.dots-container {

  //width: 100%;
  //height: 100%;
  //display: flex;
  //justify-content: center;
  //align-items: center;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

$animation-speed: 1400ms;



@mixin animation($delay: 0ms){
  animation: fx $animation-speed ease infinite $delay;
}


.dot {
  $dot-size: 15px;
  width: $dot-size;
  height: $dot-size;
  border: ($dot-size / 5) solid #448C74;
  border-radius: 50%;
  float: left;
  margin: 0 ($dot-size / 2);
  transform: scale(0);
  @include animation();
  &:nth-child(2) {
    @include animation($animation-speed * 0.3);
  }
  &:nth-child(3) {
    @include animation($animation-speed * 0.6);
  }
}

@keyframes fx {
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
